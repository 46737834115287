.contact-description{
    width: 400px;
    @media (max-width: $break-tablet){
        width: 100%;
    }
}

.subtitle-1-contact{
    @media (max-width: $break-tablet){
        display: none;
    }
}
.subtitle-1-fake-contact{
    display: none;
    @media (max-width: $break-tablet){
        display: inline;
    }
}

.subtitle-2{
    @media (max-width: $break-tablet){
        display: none;
    }
}