.red-button {
    background: transparent linear-gradient(180deg, $color-red-1 0%, $color-red-2 100%) 0% 0% no-repeat padding-box;
    color: $white;
    padding: 12px;
    font-size: 14px;
    height: 43px;
    min-width: 187px;
    border: solid 1px $white;
    border-radius: 14px;
    box-shadow: 3px 3px 5px #00000029;
    transition: background .5s, box-shadow .5s;
    &:disabled {
        opacity: .4;
        cursor: not-allowed;
    }
    &:hover,
    &:focus {
        color: $color-red-1;
        border: solid 1px $color-red-1;
        font-weight: 700;
        background: $white;
        box-shadow: none;
        outline: none;
    }
}