.header {
  height: 75px;
  width: calc(100% + 1px);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 110;
  display: flex;
  align-items: center;
  padding: 3rem 2rem;

  & ul {
    color: $white;
    display: flex;
    gap: 3rem;
    justify-content: center;
    align-items: center;
    margin: 0 1.5rem 0 auto;

    & a {
      color: $white;
      font-size: 20px;
      position: relative;

      &:hover {
        color: $color-red-2;
      }

      & span {
        display: none;
      }
    }

    @media (max-width: $break-laptop) {
      display: flex;
      flex-direction: column;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,calc(-50% - 37.5px));

      & > ul > li > a > span {
        display: inline;
        color: $white;
        font-size: 11px;
      }
    }
  }

  .menu-links-wrapper {
    @media (max-width: $break-laptop) {
      position: fixed;

      left: -1px;
      top: 100%;
      width: 101%;
      height: calc(100vh - 74px);
      margin: 0;

      padding: 0 3rem;
      gap: 1.5rem;
      background: black; //linear-gradient(#000, #0000, #0000);

      z-index: 200;

      & > ul > li > a > span {
        display: inline;
        color: $white;
        font-size: 11px;
      }
    }
  }

  @media (max-width: $break-desktop) {
    padding: 2rem 3rem;
  }
  @media (max-width: $break-laptop) {
    padding: 1rem 2rem;
    background-color: #000;
    border: solid thin #242424;
  }
  @media (max-width: $break-tablet) {
    position: fixed;
  }
}

.brand-logo {
  width: 200px;
  @media (max-width: $break-tablet) {
    width: 150px;
  }
}

.menu-btn-wrapper {
  position: relative;
}

.menu-btn {
  width: 30px;
  height: 40px;
  display: none;

  @media (max-width: $break-laptop) {
    display: block;
  }
}

.menu-btn:before,
.menu-btn:after,
.menu-btn div {
  background: $color-gray-2;
  content: "";
  display: none;
  height: 3px;
  border-radius: 3px;
  margin: 7px 0;
  transition: 0.5s;
  z-index: 101;
}
.menu-btn-clicked:before {
  transform: translateY(9px) rotate(135deg);
}
.menu-btn-clicked:after {
  transform: translateY(-11px) rotate(-135deg);
}
.menu-btn-clicked div {
  transform: scale(0);
}
@media (max-width: $break-laptop) {
  .menu-btn:before,
  .menu-btn:after,
  .menu-btn div {
    display: block;
  }
}

.phone-number-header {
  @media (max-width: $break-laptop) {
    display: none;
  }
}

.close-menu-transition {
  transition: max-height 0.5s;
  overflow: hidden;
  max-height: 0;
  @media (min-width: $break-laptop) {
    max-height: 100px;
  }
}
.open-menu-transition {
  transition: max-height 1.5s;
  max-height: 1000px;
  overflow: hidden;
  @media (min-width: $break-laptop) {
  }
}

.hover-red {
  &:hover {
    color: $color-red-2;
  }
}
