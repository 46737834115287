.home {
  height: 100vh;
  background-image: url("../../../assets/images/background-home.png");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & h1 {
    font-size: 55px;
    color: #fff;
    max-width: $break-desktop;
    margin-bottom: 5rem;

    @media (max-width: 600px) {
      margin-bottom: 3rem;
      font-size: 30px;
    }
  }

  & span {
    font-size: 3rem;

    @media (max-width: $break-tablet) {
      font-size: 2rem;
    }
  }

  & > div {
    z-index: 10;
    text-align: center;
  }
}

.home:before {
  position: absolute;
  content: "";
  background-color: rgba(36, 36, 36, 0.84);
  width: 100%;
  height: 100vh;
  z-index: 0;
}

.contact-button {
  background-color: transparent;
  text-decoration: none;
  color: #fff;
  padding: 8px 2rem;

  border-radius: 12px;
  border: solid thin #fff;
  transition: background-color 0.3s;
  font-weight: 700;
  font-size: 14px;

  &:hover {
    background-color: #cc3029;
    color: #fff;
    border: solid thin #cc3029;
    cursor: pointer;
  }
  &:focus {
    outline: none;
    background-color: #cc3029aa;
  }
}

.footer-gradient {
  height: 1.5rem;
  width: 100%;
  background: linear-gradient($color-red-1, $color-red-2);
  position: absolute;
  bottom: 0;
  left: 0;
}
