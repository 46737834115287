@font-face {
  font-family: CircularStd-Black;
  src: url("../../fonts/CircularStd-Black.otf");
}
@font-face {
  font-family: CircularStd-Bold;
  src: url("../../fonts/CircularStd-Bold.otf");
}
@font-face {
  font-family: CircularStd-Book;
  src: url("../../fonts/CircularStd-Book.otf");
}
@font-face {
  font-family: Poppins-Medium;
  src: url("../../fonts/Poppins-Medium.ttf");
}
