.see-more-info {
  height: 35vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem 1rem;

  & > div {
    max-width: 670px;
    text-align: center;
  }
}
.see-more-background-image {
  height: 30vh;
  background-image: url("../../../assets/images/bg-seemore.png");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;

  &:before {
    position: absolute;
    content: "";
    background-color: rgba(36, 36, 36, 0.84);
    width: 100%;
    height: 30vh;

    @media (max-width: $break-tablet) {
      height: 40vh;
    }
  }

  @media (max-width: $break-tablet) {
    height: 40vh;
  }
}
