.footer {
  min-height: 75px;
  width: 100%;
  color: white;
  background-color: #000;
  padding: 0 0 0.5rem 0;

  & h2 {
    font-size: 1rem;
    font-weight: 700;
  }
  & a:hover > img {
    filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(288deg);
  }
  & span {
    font-size: 16px;
  }
  & p,
  & a {
    font-size: 1rem;
  }

  & hr {
    background-color: #707070;
    opacity: 1;
  }

  // @media (max-width: 550px) {
  //   > a,
  //   p,
  //   span {
  //   }
  // }
}

.grid-footer {
  display: grid;
  grid-template-columns: min-content 1fr 1fr min-content;
  width: 100%;
  margin: auto;
  justify-content: flex-end;
  align-items: center;
  padding: 8.5rem 0 3rem 0;
  gap: 3rem 10rem;

  > div {
    align-self: flex-start;
  }

  @media (max-width: $break-desktop) {
    gap: 5rem;
  }
  @media (max-width: $break-laptop) {
    gap: 2.5rem 1rem ;
  }
  @media (max-width: 1000px) {
    grid-template-columns: 40% 40%;
  }
  @media (max-width: $break-tablet) {
    grid-template-columns: 1fr;
    padding: 6.5rem 0 4rem 0;
    > div {
      justify-self: center;
      text-align: center;
    }
  }
}


.followus {
  align-items: center;
}

.mediahaus-sign {
  font-family: "CircularStd-Book";
  font-size: 13px !important;

  > a {
    color: #1ba1fc;
    font-size: inherit;
  }
  @media (max-width: 550px) {
    color: #9d9d9d;
  }
}

.copyright {
  font-size: 14px !important;
  @media (max-width: 1000px) {
    text-align: center;
    margin: 0 0.5rem;
  }
  @media (max-width: 550px) {
    color: #707070;
  }
}

.media-core {
  font-size: 13px !important;
  font-family: "CircularStd-Book";
  color: #1ba1fc;
}

.address{
  position: relative;

  & > img{
    position: absolute;
    top: -66%;
    left: 0;
  }
}

.data-address-footer {
  align-items: center;
}

.footer-signature {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;

  @media (max-width: $break-laptop) {
    flex-direction: column;
    gap: 2.5rem;
  }
}
