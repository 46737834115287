// Breakpoints
$break-mobile: 480px;
$break-tablet: 768px;
$break-laptop: 1024px;
$break-desktop: 1200px;
$break-desktop-1: 1400px;
$break-desktop-2: 1600px;

// Colors
$color-red-1: #ff2c22;
$color-red-2: #bc251f;
$color-red-3: #CC3029;

$color-gray-1: #808080;
$color-gray-2: #C1C1C1;
$color-gray-3: #707070;
$color-gray-4: #1d8f0c;
$color-gray-5: #52ce3f;


$white: #fff;