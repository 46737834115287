// Height
@for $i from 1 through 20 {
  .h-#{$i}r {
    height: #{$i}rem;
  }
}
.mh-100{
  min-height: 100px;
}
.mh-200{
  min-height: 200px;
}
.mh-300{
  min-height: 300px;
}
.h-auto{
  height: auto;
}
.h-100p{
  height: 100%;
}
.h-50p{
  height: 50%;
}

// Width
@for $i from 1 through 20 {
  .w-#{$i}r {
    width: #{$i}rem;
  }
}

.width-100{
  width: 100px!important;
}
.width-200{
  width: 200px;
}
.width-300{
  width: 300px;
}
.miw-100{
  min-width: 100px;
  width: 100%;
}
.miw-20{
  min-width: 20px;
  width: 100%;
}
.mxw-200{
  max-width: 200px;
  width: 100%;
}
.mxw-300{
  max-width: 300px;
  width: 100%;
}
.mxw-500{
  max-width: 500px;
}

.w-max-content{
  width: max-content;
}
.w-min-content{
  width: min-content;
  background: red;
}

.w-25p{
  width: 25%;
}
.w-50p{
  width: 50%;
}
.w-100p{
  width: 100%;
}


// Margin
@for $i from 1 through 20 {
  .mt-#{$i}r {
    margin-top: #{$i}rem;
  }
}
@for $i from 1 through 20 {
  .mb-#{$i}r {
    margin-bottom: #{$i}rem;
  }
}
@for $i from 1 through 20 {
  .my-#{$i}r {
    margin-top: #{$i}rem;
    margin-bottom: #{$i}rem;
  }
}

.ml-auto{
  margin-left: auto;
}

// Padding
@for $i from 1 through 20 {
  .p-#{$i}r {
    padding: #{$i}rem;
  }
}

.pb-75px{
  @media (max-width: $break-tablet){
    padding-bottom: 75px!important;
  }
}