* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Poppins-Medium", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
a,
p,
span,
button {
  font-family: "Poppins-Medium";
}

a,
p,
span {
  color: #c1c1c1;
}

a {
  text-decoration: none;
  &:hover {
    color: white;
  }
}

.section-width {
  max-width: 1300px;
  width: 100%;
  margin: auto;
}

.select-wrapper {
  position: relative;
  z-index: 25;

  & > select {
    width: 100%;
    background-color: transparent;

    -webkit-appearance: none;
    appearance: none;
  }

  &:focus-within{
    &::after {
      top: 25%;
      transform: rotate(180deg);
    }
  }

  &::after {
    content: "";
    font-size: 1rem;
    top: 50%;
    transform: translateY(-50%);
    right: 25px;
    position: absolute;
    background: url("../../svgs/arrow-down-icon.svg");
    background-repeat: no-repeat;
    background-position: center center;

    pointer-events: none;
    padding: 15px;
    width: 15px;
    height: 15px;
  }
}

.input-contact {
  min-height: 60px;
  padding: 1rem 1.5rem;
  border: 1px solid $color-gray-3;
  border-radius: 10px;
  color: $color-gray-1;
  width: 100%;
  z-index: 35;

  &::placeholder {
    color: $color-gray-2;
    font-size: 14px;
  }

  &:focus {
    outline: none;
    border: solid 1px $color-red-1;
  }
}

.whatsapp-button {
  background: transparent
    linear-gradient(180deg, $color-gray-4 0%, $color-gray-5 100%) 0% 0% no-repeat
    padding-box;
  padding: 1rem;
  border-radius: 50%;
  position: fixed;
  bottom: 4rem;
  right: 4rem;
  z-index: 100;

  @media (max-width: $break-tablet) {
    bottom: 1rem;
    right: 1rem;
  }
}

.fixed-left-button {
  background: transparent
    linear-gradient(180deg, $color-gray-1 0%, $color-gray-3 100%) 0% 0% no-repeat
    padding-box;
  padding: .55rem;
  border-radius: 0px 12px 12px 0px;
  position: fixed;
  top: 25%;
  left: 0;
  z-index: 100;
  border: none;

  &:focus {
    outline: none;
  }

  & div {
    color: $white;
    font-weight: 700;
    font-size: 14px;

    text-orientation: upright;
    writing-mode: vertical-lr;
  }

  @media (max-width: $break-tablet) {
    top: 52%;
    padding: 0.25rem;

    & div {
      font-size: 10px;
    }
  }
}
