$small: 0.8rem;

.title-1 {
  font-size: 22px;
}
.title-2 {
  font-size: 35px;
  color: $color-gray-1;

  @media (max-width: $break-tablet) {
    font-size: 25px;
  }
}
.title-3 {
  font-size: 20px;
  color: $color-gray-1;
}
.title-4 {
  font-size: 14px;
}
.paragraph-1 {
  font-size: 17px;
  color: $color-gray-1;
  text-align: justify;

}
.link-1 {
  font-size: $small;
  text-decoration: none;
}
.link-1:any-link {
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
  font-size: $small;
}
li {
  font-size: $small;
}

// Colors
.text-black-1 {
  color: #000;
}

.text-white-1 {
  color: #fff;
}

.text-gray-1 {
  color: $color-gray-1;
}
.text-gray-2 {
  color: $color-gray-2;
}

// Size
.small-font {
  font-size: $small;
}
.font-size-16 {
  font-size: 14px;
}